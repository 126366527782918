
















































import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { collectionURLs } from '@/utils/config';

@Component
export default class BookmarkList extends Vue {
  loading = false;
  popover: any = {};
  bookmarks: any[] = [];

  edit(collection: string, id: string) {
    this.$router.push(`/questions/${collectionURLs[collection]}/edit/${id}`);
  }

  async unBookmark(id: string) {
    this.popover[id] = false;
    await UserModule.RemoveBookmark(id);
    this.bookmarks = Object.entries(UserModule.bookmarks).map((value) => value[1]);
  }

  created() {
    this.bookmarks = Object.entries(UserModule.bookmarks).map((value) => value[1]);
  }
}
