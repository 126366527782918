// export const claimOptions = [
//   { name: 'DailyQuestion', label: 'Daily Question', options: ['view', 'reset'] },
//   { name: 'Search', label: 'Search', options: ['view'] },
//   { name: 'LessonQA', label: 'Lesson Q&A', options: ['view', 'create', 'update', 'delete', 'export'] },
//   { name: 'PracticeExam', label: 'Practice Exam', options: ['view', 'create', 'update', 'delete', 'export'] },
//   { name: 'TargetedReview', label: 'Targeted Review', options: ['view', 'create', 'update', 'delete', 'export'] },
//   { name: 'TrueFalse', label: 'True/False', options: ['view', 'create', 'update', 'delete', 'export'] },
//   {
//     name: 'LearningFeature',
//     label: 'Learning Feature',
//     options: ['view', 'create', 'update', 'delete', 'export', 'configure', 'builder'],
//   },
//   { name: 'Trash', label: 'Trash', options: ['view', 'restore', 'delete'] },
//   { name: 'Users', label: 'Users', options: ['view', 'create', 'update', 'delete'] },
//   { name: 'Bookmarks', label: 'Bookmarks', options: ['view'] },
//   { name: 'Exports', label: 'Exports', options: ['view'] },
// ];
//
// export const defaultClaims = {
//   DailyQuestion: [],
//   Search: [],
//   LessonQA: [],
//   PracticeExam: [],
//   TargetedReview: [],
//   TrueFalse: [],
//   LearningFeature: [],
//   Trash: [],
//   Users: [],
//   Bookmarks: [],
//   Exports: [],
//   Admin: false,
// };

export const collectionURLs: any = {
  'Lesson QA': 'lesson-qa',
  LessonQA: 'lesson-qa',
  'Practice Exam': 'practice-exam',
  PracticeExam: 'practice-exam',
  'Targeted Review': 'targeted-review',
  TargetedReview: 'targeted-review',
  'True False': 'true-false',
  TrueFalse: 'true-false',
  'Learning Feature': 'learning-feature',
  LearningFeature: 'learning-feature',
};
